import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "cfe5a64a-3ffd-4d45-b898-0b975dfe2f58"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCardsCfe5a64a3ffd4d45B8980b975dfe2f58(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCardsCfe5a64a3ffd4d45B8980b975dfe2f58", parentTag)} postSlug="home-page-posts2" buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "2b09facd-a3a9-4f65-9bb2-2dffd17448d3"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const Title2b09facdA3a94f659bb22dffd17448d3 = "Leveling";

// id: "5671275b-a509-4b1a-95c4-015579737fd8"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPages5671275bA5094b1a95c4015579737fd8(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPages5671275bA5094b1a95c4015579737fd8`}>
      {props.children}
    </div>
  );
}

// id: "f2d0a3fb-cec6-4500-9a36-3b5d2d6603fb"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeaderF2d0a3fbCec645009a363b5d2d6603fb(props: any) {
  return (
    <ShortHeader893c048d031649458577C5f294cf26f6 parentTag="DefaultHeaderF2d0a3fbCec645009a363b5d2d6603fb" title navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "fe61fbe3-5f23-4f6d-aa46-a8b649d2bbbd"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeaderFe61fbe35f234f6dAa46A8b649d2bbbd(props: any) {
  return (
    <ShortHeader893c048d031649458577C5f294cf26f6 parentTag="MembersAreaHeaderFe61fbe35f234f6dAa46A8b649d2bbbd" header-top={<DefaultHeaderTopA9b59c5d2bf44530A98685411bd8c3a5 parentTag="HeaderTop0872b38fEf4b400eA2bc36541bbdd635" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "b777e83b-dc03-4bfb-a25c-edaf5e51c872"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBodyB777e83bDc034bfbA25cEdaf5e51c872(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBodyB777e83bDc034bfbA25cEdaf5e51c872 page-body`}>
      {props["marketingCards"] || <MarketingCardsCfe5a64a3ffd4d45B8980b975dfe2f58 parentTag="MarketingCards0867cb7c29bb431fB19d0292ee4b5344" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "2b3df8c9-2941-452a-88d7-c4452d95b16a"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage2b3df8c92941452a88d7C4452d95b16a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage2b3df8c92941452a88d7C4452d95b16a page`}>
      {props["header"] || <SecondaryMarketingHeader90329289D1e74df2Ac428e2dc71f852a parentTag="Header3415a38731c349daA09a790f2cb1917b" title="About Us" {...props} />}
      {props["body"] || <MarketingBodyB777e83bDc034bfbA25cEdaf5e51c872 parentTag="Body771c2b5b9f0a4497A7c8C9c9647fc51e" postSlug="about-us-posts" {...props} />}
      {props["footer"] || <DefaultFooter8ce2bfceB426499d8921372b6b074c1a parentTag="Footer5d756c0d6b804ec98a7b20ccf4c2eda3" {...props} />}
    </div>
  );
}

// id: "f136ffc7-6b8b-4e6b-ae26-a1e2e2b1bc75"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPageF136ffc76b8b4e6bAe26A1e2e2b1bc75(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPageF136ffc76b8b4e6bAe26A1e2e2b1bc75`}>
      {props["header"] || <SecondaryMarketingHeader90329289D1e74df2Ac428e2dc71f852a parentTag="Header3af59ec65f54481eB9692645ff3e6d7f" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter8ce2bfceB426499d8921372b6b074c1a parentTag="Footer8e74b66c32864d4597b4E0d9acfa35b0" {...props} />}
    </div>
  );
}

// id: "fbe06bf2-533b-4a2e-9c58-398b64268edf"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPagesFbe06bf2533b4a2e9c58398b64268edf(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPagesFbe06bf2533b4a2e9c58398b64268edf gf-page-layout`}>
      {props["header"] || <MembersAreaHeaderFe61fbe35f234f6dAa46A8b649d2bbbd parentTag="Header7286e8267b984875Bd50Bc2070fe4596" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNav5044db2fBf9546aaBaa16c7e78d15f3f", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter8ce2bfceB426499d8921372b6b074c1a parentTag="FooterFafc8816A0ba4303Be34D425b2efc728" {...props} />}
    </div>
  );
}

// id: "b6dc238d-e935-402a-be94-fd6ffc61b42f"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefaultB6dc238dE935402aBe94Fd6ffc61b42f(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefaultB6dc238dE935402aBe94Fd6ffc61b42f page`}>
      {props["header"] || <MembersAreaHeaderFe61fbe35f234f6dAa46A8b649d2bbbd parentTag="Header5dfb60514ecf4cf48fecE26011afb458" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooter8ce2bfceB426499d8921372b6b074c1a parentTag="Footer59766fd427344a0d93b1403377190dce" {...props} />}
    </div>
  );
}

// id: "422d133b-c3bd-4797-8830-88481771286b"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage422d133bC3bd4797883088481771286b(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage422d133bC3bd4797883088481771286b`}>
      {props["header"] || <SecondaryMarketingHeader90329289D1e74df2Ac428e2dc71f852a parentTag="Header734baf256a394226943fF635f5d036df" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooter8ce2bfceB426499d8921372b6b074c1a parentTag="FooterAf01124a2a12493e98fcA85973ddd253" {...props} />}
    </div>
  );
}

// id: "53dd25b0-549f-47b9-b87e-a47858d01f24"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage53dd25b0549f47b9B87eA47858d01f24(props: any) {
  return (
    <SecondaryPage422d133bC3bd4797883088481771286b parentTag="ResourcesPage53dd25b0549f47b9B87eA47858d01f24" header={<SecondaryMarketingHeader90329289D1e74df2Ac428e2dc71f852a parentTag="Header89d6d7eb71fe4d219cb17baaf0d0f623" title="Resources" />} {...props} />
  );
}

// id: "90bdcc2e-1c65-4c72-b94c-ac4ad8557a5a"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label90bdcc2e1c654c72B94cAc4ad8557a5a(props: any) {
  return (
    <DefaultLogoA38382a473dc4ddb9b44E054acadfacb parentTag="Label90bdcc2e1c654c72B94cAc4ad8557a5a" {...props} />
  );
}

// id: "893c048d-0316-4945-8577-c5f294cf26f6"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeader893c048d031649458577C5f294cf26f6(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeader893c048d031649458577C5f294cf26f6 site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogoFd9e5da010ea456dA067B9a267fa6e90", parentTag)} label={<DefaultLogoA38382a473dc4ddb9b44E054acadfacb parentTag="LabelD0e34a4d8707406999c965bad58d833a" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation7327d444254f44839c3c84008d352a7e", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "a38382a4-73dc-4ddb-9b44-e054acadfacb"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogoA38382a473dc4ddb9b44E054acadfacb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg" className={`${parentTag || ""} DefaultLogoA38382a473dc4ddb9b44E054acadfacb logo`} alt="logo" />
  );
}

// id: "9d2d3e21-e280-476e-9b92-a63082a86bc9"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote9d2d3e21E280476e9b92A63082a86bc9(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote9d2d3e21E280476e9b92A63082a86bc9`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "b36a41cd-fe34-4146-aa65-4f78254999fe"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPageB36a41cdFe344146Aa654f78254999fe(props: any) {
  return (
    <SecondaryPage422d133bC3bd4797883088481771286b parentTag="JoinUsPageB36a41cdFe344146Aa654f78254999fe" header={<SecondaryMarketingHeader90329289D1e74df2Ac428e2dc71f852a parentTag="HeaderEde6ddf98a0b4d178175429a652315fb" title="Join Us" />} {...props} />
  );
}

// id: "c1c31437-7c55-4322-8941-c93b83e7f9ec"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const HrefC1c314377c5543228941C93b83e7f9ec = "/";

// id: "5868817a-79dd-4972-aae6-a481fe417d06"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function Footer5868817a79dd4972Aae6A481fe417d06(props: any) {
  return (
    <DefaultFooter8ce2bfceB426499d8921372b6b074c1a parentTag="Footer5868817a79dd4972Aae6A481fe417d06" {...props} />
  );
}

// id: "97a10b00-b86e-44bd-9ac0-e49bbaccf21d"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPages97a10b00B86e44bd9ac0E49bbaccf21d(props: any) {
  return (
    <SecondaryPage422d133bC3bd4797883088481771286b parentTag="SignupPages97a10b00B86e44bd9ac0E49bbaccf21d" header={<SecondaryMarketingHeader90329289D1e74df2Ac428e2dc71f852a parentTag="Header4d178ac14ef5401eB6e81983dc597ed9" title="Join Us" />} {...props} />
  );
}

// id: "06222c36-b1b5-493c-af21-4a6039516320"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero06222c36B1b5493cAf214a6039516320(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero06222c36B1b5493cAf214a6039516320 hero`}>
      <div className="title">
        {props["title"] || Title25eab6aaFeb14735Abba977458fcbbd7}
      </div>
    </div>
  );
}

// id: "bde89279-c7bd-44b8-a212-373733713505"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function HeaderBde89279C7bd44b8A212373733713505(props: any) {
  return (
    <MembersAreaHeaderFe61fbe35f234f6dAa46A8b649d2bbbd parentTag="HeaderBde89279C7bd44b8A212373733713505" {...props} />
  );
}

// id: "8ce2bfce-b426-499d-8921-372b6b074c1a"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooter8ce2bfceB426499d8921372b6b074c1a(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooter8ce2bfceB426499d8921372b6b074c1a footer`}>
      {props["links"] || <Navigation parentTag={buildClassName("Links885aa121967f432bA79136962dc137b8", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <Logo25ebbc1f776b492994a9698451297f1c />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("Copyright9cfe1f851e42453a812920493759de7b", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("GroupflowC2153c5277a84bf684cf6eaa2b1269b0", parentTag)} {...props} />}
    </div>
  );
}

// id: "a9b59c5d-2bf4-4530-a986-85411bd8c3a5"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTopA9b59c5d2bf44530A98685411bd8c3a5(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTopA9b59c5d2bf44530A98685411bd8c3a5 header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo4ee24f7392654f27885aE6709d06ef60", parentTag)} url="/" className="linked-logo" label={<DefaultLogoA38382a473dc4ddb9b44E054acadfacb parentTag="Label8e425af2900548da8c3a2b64ebe124d5" alt="logo" />} {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("NavigationC7511b7166034ee189921edf1cfea4dd", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
    </div>
  );
}

// id: "4eca4826-b85a-4752-bff8-413a028e3644"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPage4eca4826B85a4752Bff8413a028e3644(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPage4eca4826B85a4752Bff8413a028e3644`}>
      {props["header"] || <SecondaryMarketingHeader90329289D1e74df2Ac428e2dc71f852a parentTag="HeaderB53ed8be6d5a4c7dAe175bcbbbbcde2d" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter8ce2bfceB426499d8921372b6b074c1a parentTag="Footer5f0d4f2471b944dbA3f6365fd0f2a058" {...props} />}
    </div>
  );
}

// id: "fdf11a99-8d7f-4b54-b98d-57e0edc6a8a5"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePagesFdf11a998d7f4b54B98d57e0edc6a8a5(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePagesFdf11a998d7f4b54B98d57e0edc6a8a5`}>
      {props["header"] || <MembersAreaHeaderFe61fbe35f234f6dAa46A8b649d2bbbd parentTag="Header8df78367509f4d5d80b3Fc31f6f6dd6a" {...props} />}
      {props.children}
    </div>
  );
}

// id: "a827bd34-93da-4134-88d6-d188fe5447fe"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHeroA827bd3493da413488d6D188fe5447fe(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHeroA827bd3493da413488d6D188fe5447fe hero`}>
      <div className="title">
        Our mission is to change the world by promoting healthy intimacy through community and education.
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("ButtonA7a2e25d5ac248b3B149Aa5658753617", parentTag)} href="/join" label="Join Us" {...props} />}
      </div>
    </div>
  );
}

// id: "90329289-d1e7-4df2-ac42-8e2dc71f852a"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader90329289D1e74df2Ac428e2dc71f852a(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader90329289D1e74df2Ac428e2dc71f852a site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopA9b59c5d2bf44530A98685411bd8c3a5 parentTag="HeaderTop9296f890357c494bB248A9e12b509136" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero06222c36B1b5493cAf214a6039516320 parentTag="HeroC7d8be7f42494012B66e02830715fc93" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "d0158d35-14c6-4110-b470-f271dfcf2aad"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePageD0158d3514c64110B470F271dfcf2aad(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageD0158d3514c64110B470F271dfcf2aad page`}>
      {props["header"] || <HomeHeaderA0836cebC83244959b8aBbdaebbc9654 parentTag="Header5d0fd9a6Bc534dfe9e272d53bcadc6b4" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBackEa6f50b310a640d69f1eA467972320fa", parentTag)} {...props} />}
      {props["body"] || <MarketingBodyB777e83bDc034bfbA25cEdaf5e51c872 parentTag="BodyE3aa356a528040618ef7Fbe546c576a7" {...props} />}
      {props["quote"] || <PageQuote parentTag={buildClassName("Quote3a39c5d5Fe9243e8Afb1Ad262918ed6d", parentTag)} contentSlug="home-page-quote" {...props} />}
      {props["footer"] || <DefaultFooter8ce2bfceB426499d8921372b6b074c1a parentTag="Footer9cd3011aDda341b7Bf27A2ca70c68cbd" {...props} />}
    </div>
  );
}

// id: "a0836ceb-c832-4495-9b8a-bbdaebbc9654"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeaderA0836cebC83244959b8aBbdaebbc9654(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeaderA0836cebC83244959b8aBbdaebbc9654 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopA9b59c5d2bf44530A98685411bd8c3a5 parentTag="HeaderTop17333cbaCc1f40c1B41e167509dc9552" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />}
      </div>
      {props["hero"] || <HomePageHeroA827bd3493da413488d6D188fe5447fe parentTag="HeroD880fe19Acdd41b1A22d08caa6af4bea" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />}
    </div>
  );
}

// id: "885aa121-967f-432b-a791-36962dc137b8"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "8ce2bfce-b426-499d-8921-372b6b074c1a"
export function Links885aa121967f432bA79136962dc137b8(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("Links885aa121967f432bA79136962dc137b8", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "c7d8be7f-4249-4012-b66e-02830715fc93"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "90329289-d1e7-4df2-ac42-8e2dc71f852a"
export function HeroC7d8be7f42494012B66e02830715fc93(props: any) {
  return (
    <SecondaryMarketingHero06222c36B1b5493cAf214a6039516320 parentTag="HeroC7d8be7f42494012B66e02830715fc93" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "fafc8816-a0ba-4303-be34-d425b2efc728"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "fbe06bf2-533b-4a2e-9c58-398b64268edf"
export function FooterFafc8816A0ba4303Be34D425b2efc728(props: any) {
  return (
    <DefaultFooter8ce2bfceB426499d8921372b6b074c1a parentTag="FooterFafc8816A0ba4303Be34D425b2efc728" {...props} />
  );
}

// id: "5dfb6051-4ecf-4cf4-8fec-e26011afb458"
// title: ""
// type: :reference
// key: "header"
// parent_id: "b6dc238d-e935-402a-be94-fd6ffc61b42f"
export function Header5dfb60514ecf4cf48fecE26011afb458(props: any) {
  return (
    <MembersAreaHeaderFe61fbe35f234f6dAa46A8b649d2bbbd parentTag="Header5dfb60514ecf4cf48fecE26011afb458" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "7286e826-7b98-4875-bd50-bc2070fe4596"
// title: ""
// type: :reference
// key: "header"
// parent_id: "fbe06bf2-533b-4a2e-9c58-398b64268edf"
export function Header7286e8267b984875Bd50Bc2070fe4596(props: any) {
  return (
    <MembersAreaHeaderFe61fbe35f234f6dAa46A8b649d2bbbd parentTag="Header7286e8267b984875Bd50Bc2070fe4596" {...props} />
  );
}

// id: "5d0fd9a6-bc53-4dfe-9e27-2d53bcadc6b4"
// title: ""
// type: :reference
// key: "header"
// parent_id: "d0158d35-14c6-4110-b470-f271dfcf2aad"
export function Header5d0fd9a6Bc534dfe9e272d53bcadc6b4(props: any) {
  return (
    <HomeHeaderA0836cebC83244959b8aBbdaebbc9654 parentTag="Header5d0fd9a6Bc534dfe9e272d53bcadc6b4" {...props} />
  );
}

// id: "ea6f50b3-10a6-40d6-9f1e-a467972320fa"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "d0158d35-14c6-4110-b470-f271dfcf2aad"
export function WelcomeBackEa6f50b310a640d69f1eA467972320fa(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBackEa6f50b310a640d69f1eA467972320fa", parentTag)} {...props} />
  );
}

// id: "5d756c0d-6b80-4ec9-8a7b-20ccf4c2eda3"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "2b3df8c9-2941-452a-88d7-c4452d95b16a"
export function Footer5d756c0d6b804ec98a7b20ccf4c2eda3(props: any) {
  return (
    <DefaultFooter8ce2bfceB426499d8921372b6b074c1a parentTag="Footer5d756c0d6b804ec98a7b20ccf4c2eda3" {...props} />
  );
}

// id: "59766fd4-2734-4a0d-93b1-403377190dce"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "b6dc238d-e935-402a-be94-fd6ffc61b42f"
export function Footer59766fd427344a0d93b1403377190dce(props: any) {
  return (
    <DefaultFooter8ce2bfceB426499d8921372b6b074c1a parentTag="Footer59766fd427344a0d93b1403377190dce" {...props} />
  );
}

// id: "9cd3011a-dda3-41b7-bf27-a2ca70c68cbd"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "d0158d35-14c6-4110-b470-f271dfcf2aad"
export function Footer9cd3011aDda341b7Bf27A2ca70c68cbd(props: any) {
  return (
    <DefaultFooter8ce2bfceB426499d8921372b6b074c1a parentTag="Footer9cd3011aDda341b7Bf27A2ca70c68cbd" {...props} />
  );
}

// id: "734baf25-6a39-4226-943f-f635f5d036df"
// title: ""
// type: :reference
// key: "header"
// parent_id: "422d133b-c3bd-4797-8830-88481771286b"
export function Header734baf256a394226943fF635f5d036df(props: any) {
  return (
    <SecondaryMarketingHeader90329289D1e74df2Ac428e2dc71f852a parentTag="Header734baf256a394226943fF635f5d036df" title="Secondary Page" {...props} />
  );
}

// id: "ec2f7cfb-cde5-4a8b-8032-9b573ad5b364"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "cfe5a64a-3ffd-4d45-b898-0b975dfe2f58"
export const PostSlugEc2f7cfbCde54a8b80329b573ad5b364 = "home-page-posts2";

// id: "8df78367-509f-4d5d-80b3-fc31f6f6dd6a"
// title: ""
// type: :reference
// key: "header"
// parent_id: "fdf11a99-8d7f-4b54-b98d-57e0edc6a8a5"
export function Header8df78367509f4d5d80b3Fc31f6f6dd6a(props: any) {
  return (
    <MembersAreaHeaderFe61fbe35f234f6dAa46A8b649d2bbbd parentTag="Header8df78367509f4d5d80b3Fc31f6f6dd6a" {...props} />
  );
}

// id: "4ee24f73-9265-4f27-885a-e6709d06ef60"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "a9b59c5d-2bf4-4530-a986-85411bd8c3a5"
export function LinkedLogo4ee24f7392654f27885aE6709d06ef60(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo4ee24f7392654f27885aE6709d06ef60", parentTag)} url="/" className="linked-logo" label={<DefaultLogoA38382a473dc4ddb9b44E054acadfacb parentTag="Label8e425af2900548da8c3a2b64ebe124d5" alt="logo" />} {...props} />
  );
}

// id: "e3aa356a-5280-4061-8ef7-fbe546c576a7"
// title: ""
// type: :reference
// key: "body"
// parent_id: "d0158d35-14c6-4110-b470-f271dfcf2aad"
export function BodyE3aa356a528040618ef7Fbe546c576a7(props: any) {
  return (
    <MarketingBodyB777e83bDc034bfbA25cEdaf5e51c872 parentTag="BodyE3aa356a528040618ef7Fbe546c576a7" {...props} />
  );
}

// id: "b53ed8be-6d5a-4c7d-ae17-5bcbbbbcde2d"
// title: ""
// type: :reference
// key: "header"
// parent_id: "4eca4826-b85a-4752-bff8-413a028e3644"
export function HeaderB53ed8be6d5a4c7dAe175bcbbbbcde2d(props: any) {
  return (
    <SecondaryMarketingHeader90329289D1e74df2Ac428e2dc71f852a parentTag="HeaderB53ed8be6d5a4c7dAe175bcbbbbcde2d" title="Leveling" {...props} />
  );
}

// id: "d880fe19-acdd-41b1-a22d-08caa6af4bea"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "a0836ceb-c832-4495-9b8a-bbdaebbc9654"
export function HeroD880fe19Acdd41b1A22d08caa6af4bea(props: any) {
  return (
    <HomePageHeroA827bd3493da413488d6D188fe5447fe parentTag="HeroD880fe19Acdd41b1A22d08caa6af4bea" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />
  );
}

// id: "195ac1db-1952-42ce-8e25-66429166f30b"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "cfe5a64a-3ffd-4d45-b898-0b975dfe2f58"
export const ButtonClass195ac1db195242ce8e2566429166f30b = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "5044db2f-bf95-46aa-baa1-6c7e78d15f3f"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "fbe06bf2-533b-4a2e-9c58-398b64268edf"
export function SecondaryNav5044db2fBf9546aaBaa16c7e78d15f3f(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNav5044db2fBf9546aaBaa16c7e78d15f3f", parentTag)} {...props} />
  );
}

// id: "9cfe1f85-1e42-453a-8129-20493759de7b"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "8ce2bfce-b426-499d-8921-372b6b074c1a"
export function Copyright9cfe1f851e42453a812920493759de7b(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright9cfe1f851e42453a812920493759de7b", parentTag)} {...props} />
  );
}

// id: "fd9e5da0-10ea-456d-a067-b9a267fa6e90"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "893c048d-0316-4945-8577-c5f294cf26f6"
export function LinkedLogoFd9e5da010ea456dA067B9a267fa6e90(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogoFd9e5da010ea456dA067B9a267fa6e90", parentTag)} label={<DefaultLogoA38382a473dc4ddb9b44E054acadfacb parentTag="LabelD0e34a4d8707406999c965bad58d833a" alt="logo" />} {...props} />
  );
}

// id: "4d178ac1-4ef5-401e-b6e8-1983dc597ed9"
// title: ""
// type: :reference
// key: "header"
// parent_id: "97a10b00-b86e-44bd-9ac0-e49bbaccf21d"
export function Header4d178ac14ef5401eB6e81983dc597ed9(props: any) {
  return (
    <SecondaryMarketingHeader90329289D1e74df2Ac428e2dc71f852a parentTag="Header4d178ac14ef5401eB6e81983dc597ed9" title="Join Us" {...props} />
  );
}

// id: "50fb55ca-1f5b-4d57-a884-9b603985d769"
// title: ""
// type: :text
// key: "title"
// parent_id: "f2d0a3fb-cec6-4500-9a36-3b5d2d6603fb"
export const Title50fb55ca1f5b4d57A8849b603985d769 = null;

// id: "3dd86d7f-adf5-48c1-96df-9e1cce0681a4"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "893c048d-0316-4945-8577-c5f294cf26f6"
export const BackgroundImage3dd86d7fAdf548c196df9e1cce0681a4 = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "0867cb7c-29bb-431f-b19d-0292ee4b5344"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "b777e83b-dc03-4bfb-a25c-edaf5e51c872"
export function MarketingCards0867cb7c29bb431fB19d0292ee4b5344(props: any) {
  return (
    <MarketingCardsCfe5a64a3ffd4d45B8980b975dfe2f58 parentTag="MarketingCards0867cb7c29bb431fB19d0292ee4b5344" postSlug="home-page-posts2" {...props} />
  );
}

// id: "a7a2e25d-5ac2-48b3-b149-aa5658753617"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "a827bd34-93da-4134-88d6-d188fe5447fe"
export function ButtonA7a2e25d5ac248b3B149Aa5658753617(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("ButtonA7a2e25d5ac248b3B149Aa5658753617", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "3af59ec6-5f54-481e-b969-2645ff3e6d7f"
// title: ""
// type: :reference
// key: "header"
// parent_id: "f136ffc7-6b8b-4e6b-ae26-a1e2e2b1bc75"
export function Header3af59ec65f54481eB9692645ff3e6d7f(props: any) {
  return (
    <SecondaryMarketingHeader90329289D1e74df2Ac428e2dc71f852a parentTag="Header3af59ec65f54481eB9692645ff3e6d7f" title="Public Events" {...props} />
  );
}

// id: "af01124a-2a12-493e-98fc-a85973ddd253"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "422d133b-c3bd-4797-8830-88481771286b"
export function FooterAf01124a2a12493e98fcA85973ddd253(props: any) {
  return (
    <DefaultFooter8ce2bfceB426499d8921372b6b074c1a parentTag="FooterAf01124a2a12493e98fcA85973ddd253" {...props} />
  );
}

// id: "5f0d4f24-71b9-44db-a3f6-365fd0f2a058"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "4eca4826-b85a-4752-bff8-413a028e3644"
export function Footer5f0d4f2471b944dbA3f6365fd0f2a058(props: any) {
  return (
    <DefaultFooter8ce2bfceB426499d8921372b6b074c1a parentTag="Footer5f0d4f2471b944dbA3f6365fd0f2a058" {...props} />
  );
}

// id: "c7511b71-6603-4ee1-8992-1edf1cfea4dd"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "a9b59c5d-2bf4-4530-a986-85411bd8c3a5"
export function NavigationC7511b7166034ee189921edf1cfea4dd(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("NavigationC7511b7166034ee189921edf1cfea4dd", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "3415a387-31c3-49da-a09a-790f2cb1917b"
// title: ""
// type: :reference
// key: "header"
// parent_id: "2b3df8c9-2941-452a-88d7-c4452d95b16a"
export function Header3415a38731c349daA09a790f2cb1917b(props: any) {
  return (
    <SecondaryMarketingHeader90329289D1e74df2Ac428e2dc71f852a parentTag="Header3415a38731c349daA09a790f2cb1917b" title="About Us" {...props} />
  );
}

// id: "9296f890-357c-494b-b248-a9e12b509136"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "90329289-d1e7-4df2-ac42-8e2dc71f852a"
export function HeaderTop9296f890357c494bB248A9e12b509136(props: any) {
  return (
    <DefaultHeaderTopA9b59c5d2bf44530A98685411bd8c3a5 parentTag="HeaderTop9296f890357c494bB248A9e12b509136" {...props} />
  );
}

// id: "8e74b66c-3286-4d45-97b4-e0d9acfa35b0"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "f136ffc7-6b8b-4e6b-ae26-a1e2e2b1bc75"
export function Footer8e74b66c32864d4597b4E0d9acfa35b0(props: any) {
  return (
    <DefaultFooter8ce2bfceB426499d8921372b6b074c1a parentTag="Footer8e74b66c32864d4597b4E0d9acfa35b0" {...props} />
  );
}

// id: "25eab6aa-feb1-4735-abba-977458fcbbd7"
// title: ""
// type: :text
// key: "title"
// parent_id: "06222c36-b1b5-493c-af21-4a6039516320"
export const Title25eab6aaFeb14735Abba977458fcbbd7 = "Default Title";

// id: "89d6d7eb-71fe-4d21-9cb1-7baaf0d0f623"
// title: ""
// type: :reference
// key: "header"
// parent_id: "53dd25b0-549f-47b9-b87e-a47858d01f24"
export function Header89d6d7eb71fe4d219cb17baaf0d0f623(props: any) {
  return (
    <SecondaryMarketingHeader90329289D1e74df2Ac428e2dc71f852a parentTag="Header89d6d7eb71fe4d219cb17baaf0d0f623" title="Resources" {...props} />
  );
}

// id: "0f121132-5ba9-47b5-a0b1-766d693ed5ce"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "a0836ceb-c832-4495-9b8a-bbdaebbc9654"
export const BackgroundImage0f1211325ba947b5A0b1766d693ed5ce = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp";

// id: "7327d444-254f-4483-9c3c-84008d352a7e"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "893c048d-0316-4945-8577-c5f294cf26f6"
export function Navigation7327d444254f44839c3c84008d352a7e(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation7327d444254f44839c3c84008d352a7e", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "25ebbc1f-776b-4929-94a9-698451297f1c"
// title: ""
// type: :html
// key: "logo"
// parent_id: "8ce2bfce-b426-499d-8921-372b6b074c1a"
export function Logo25ebbc1f776b492994a9698451297f1c(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide-white-with-font.svg" alt="Demo 1 logo" className={`${parentTag || ""} Logo25ebbc1f776b492994a9698451297f1c logo`} />
  );
}

// id: "0872b38f-ef4b-400e-a2bc-36541bbdd635"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "fe61fbe3-5f23-4f6d-aa46-a8b649d2bbbd"
export function HeaderTop0872b38fEf4b400eA2bc36541bbdd635(props: any) {
  return (
    <DefaultHeaderTopA9b59c5d2bf44530A98685411bd8c3a5 parentTag="HeaderTop0872b38fEf4b400eA2bc36541bbdd635" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "ede6ddf9-8a0b-4d17-8175-429a652315fb"
// title: ""
// type: :reference
// key: "header"
// parent_id: "b36a41cd-fe34-4146-aa65-4f78254999fe"
export function HeaderEde6ddf98a0b4d178175429a652315fb(props: any) {
  return (
    <SecondaryMarketingHeader90329289D1e74df2Ac428e2dc71f852a parentTag="HeaderEde6ddf98a0b4d178175429a652315fb" title="Join Us" {...props} />
  );
}

// id: "89b3e307-743c-4389-b3aa-fb4f274ee059"
// title: ""
// type: :text
// key: "alt"
// parent_id: "a38382a4-73dc-4ddb-9b44-e054acadfacb"
export const Alt89b3e307743c4389B3aaFb4f274ee059 = "logo";

// id: "c2153c52-77a8-4bf6-84cf-6eaa2b1269b0"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "8ce2bfce-b426-499d-8921-372b6b074c1a"
export function GroupflowC2153c5277a84bf684cf6eaa2b1269b0(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("GroupflowC2153c5277a84bf684cf6eaa2b1269b0", parentTag)} {...props} />
  );
}

// id: "a626566a-2117-4e8f-b1cd-5b69b7383dbc"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "f2d0a3fb-cec6-4500-9a36-3b5d2d6603fb"
export const NavMenuSlugA626566a21174e8fB1cd5b69b7383dbc = "marketing-primary-nav";

// id: "771c2b5b-9f0a-4497-a7c8-c9c9647fc51e"
// title: ""
// type: :reference
// key: "body"
// parent_id: "2b3df8c9-2941-452a-88d7-c4452d95b16a"
export function Body771c2b5b9f0a4497A7c8C9c9647fc51e(props: any) {
  return (
    <MarketingBodyB777e83bDc034bfbA25cEdaf5e51c872 parentTag="Body771c2b5b9f0a4497A7c8C9c9647fc51e" postSlug="about-us-posts" {...props} />
  );
}

// id: "3a39c5d5-fe92-43e8-afb1-ad262918ed6d"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "d0158d35-14c6-4110-b470-f271dfcf2aad"
export function Quote3a39c5d5Fe9243e8Afb1Ad262918ed6d(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("Quote3a39c5d5Fe9243e8Afb1Ad262918ed6d", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "4bbba4fd-52a0-4432-affe-59e747356668"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "a38382a4-73dc-4ddb-9b44-e054acadfacb"
export const ImageUrl4bbba4fd52a04432Affe59e747356668 = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg";

// id: "7c8783cb-ac05-4cfe-addd-9376c5abc003"
// title: ""
// type: :text
// key: "title"
// parent_id: "90329289-d1e7-4df2-ac42-8e2dc71f852a"
export const Title7c8783cbAc054cfeAddd9376c5abc003 = "Secondary Marketing Title 1";

// id: "17333cba-cc1f-40c1-b41e-167509dc9552"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "a0836ceb-c832-4495-9b8a-bbdaebbc9654"
export function HeaderTop17333cbaCc1f40c1B41e167509dc9552(props: any) {
  return (
    <DefaultHeaderTopA9b59c5d2bf44530A98685411bd8c3a5 parentTag="HeaderTop17333cbaCc1f40c1B41e167509dc9552" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />
  );
}

// id: "9a901417-56e3-4758-abd6-032458e903b9"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "d880fe19-acdd-41b1-a22d-08caa6af4bea"
export const WidgetId9a90141756e34758Abd6032458e903b9 = "4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d";

// id: "6baf44ee-ab02-4359-93a3-40e4d1ec519c"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "17333cba-cc1f-40c1-b41e-167509dc9552"
export const WidgetId6baf44eeAb02435993a340e4d1ec519c = "6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5";

// id: "ec786590-15f0-41a9-8ff4-357c56e1cfff"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "0872b38f-ef4b-400e-a2bc-36541bbdd635"
export const NavMenuSlugEc78659015f041a98ff4357c56e1cfff = "members-primary-nav";

// id: "3859f80d-c2c2-47f1-b50d-2f9371bf4954"
// title: ""
// type: :text
// key: "href"
// parent_id: "a7a2e25d-5ac2-48b3-b149-aa5658753617"
export const Href3859f80dC2c247f1B50d2f9371bf4954 = "/join";

// id: "8bbae141-7ddc-4b4a-8cec-73e8ae95a2c8"
// title: ""
// type: :text
// key: "title"
// parent_id: "3af59ec6-5f54-481e-b969-2645ff3e6d7f"
export const Title8bbae1417ddc4b4a8cec73e8ae95a2c8 = "Public Events";

// id: "c1cc8fcd-065b-409c-941d-91fc78afb3ba"
// title: ""
// type: :text
// key: "label"
// parent_id: "a7a2e25d-5ac2-48b3-b149-aa5658753617"
export const LabelC1cc8fcd065b409c941d91fc78afb3ba = "Join Us";

// id: "5f9d7f3e-f6c5-471a-bb17-a205c6d3ed06"
// title: ""
// type: :text
// key: "url"
// parent_id: "4ee24f73-9265-4f27-885a-e6709d06ef60"
export const Url5f9d7f3eF6c5471aBb17A205c6d3ed06 = "/";

// id: "c41c8b7d-3d93-46ac-9611-06b2e0eb82c7"
// title: ""
// type: :text
// key: "class"
// parent_id: "c7511b71-6603-4ee1-8992-1edf1cfea4dd"
export const ClassC41c8b7d3d9346ac961106b2e0eb82c7 = "navigation";

// id: "5c2725ed-2ca2-4b95-bec5-e1b63b3f2da7"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "3a39c5d5-fe92-43e8-afb1-ad262918ed6d"
export const ContentSlug5c2725ed2ca24b95Bec5E1b63b3f2da7 = "home-page-quote";

// id: "d8791d6f-7f1e-4de6-adf6-2851fc38611b"
// title: ""
// type: :text
// key: "title"
// parent_id: "734baf25-6a39-4226-943f-f635f5d036df"
export const TitleD8791d6f7f1e4de6Adf62851fc38611b = "Secondary Page";

// id: "f42144db-96dc-4b28-852d-ff618f1931a9"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "885aa121-967f-432b-a791-36962dc137b8"
export const NavMenuSlugF42144db96dc4b28852dFf618f1931a9 = "footer-nav";

// id: "7880d464-e87d-4823-bec6-8785e6e5b316"
// title: ""
// type: :text
// key: "class"
// parent_id: "4ee24f73-9265-4f27-885a-e6709d06ef60"
export const Class7880d464E87d4823Bec68785e6e5b316 = "linked-logo";

// id: "3d5c2b54-320b-42f7-b7b8-5dc6adf8eb95"
// title: ""
// type: :text
// key: "title"
// parent_id: "c7d8be7f-4249-4012-b66e-02830715fc93"
export const Title3d5c2b54320b42f7B7b85dc6adf8eb95 = "Secondary Marketing Title 2";

// id: "0880ef23-4fd9-47d6-ae9b-79c932795bde"
// title: ""
// type: :text
// key: "title"
// parent_id: "3415a387-31c3-49da-a09a-790f2cb1917b"
export const Title0880ef234fd947d6Ae9b79c932795bde = "About Us";

// id: "3cebc62a-bb21-4e7e-b8fd-ee9c63e1540f"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "5dfb6051-4ecf-4cf4-8fec-e26011afb458"
export const NavMenuSlug3cebc62aBb214e7eB8fdEe9c63e1540f = "members-primary-nav";

// id: "bd07ffd9-eb85-4d3b-abc5-01990681f7f5"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "c7511b71-6603-4ee1-8992-1edf1cfea4dd"
export const NavMenuSlugBd07ffd9Eb854d3bAbc501990681f7f5 = "marketing-primary-nav";

// id: "7c1e46bc-7428-4498-ae45-4dd869516e7e"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "0867cb7c-29bb-431f-b19d-0292ee4b5344"
export const PostSlug7c1e46bc74284498Ae454dd869516e7e = "home-page-posts2";

// id: "d0e34a4d-8707-4069-99c9-65bad58d833a"
// title: ""
// type: :reference
// key: "label"
// parent_id: "fd9e5da0-10ea-456d-a067-b9a267fa6e90"
export function LabelD0e34a4d8707406999c965bad58d833a(props: any) {
  return (
    <DefaultLogoA38382a473dc4ddb9b44E054acadfacb parentTag="LabelD0e34a4d8707406999c965bad58d833a" {...props} />
  );
}

// id: "4e906c65-b82b-4c1c-a444-e9b58b2d1249"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "771c2b5b-9f0a-4497-a7c8-c9c9647fc51e"
export const PostSlug4e906c65B82b4c1cA444E9b58b2d1249 = "about-us-posts";

// id: "84dc10da-9678-497f-8581-f24cba594233"
// title: ""
// type: :text
// key: "title"
// parent_id: "ede6ddf9-8a0b-4d17-8175-429a652315fb"
export const Title84dc10da9678497f8581F24cba594233 = "Join Us";

// id: "a963cc5d-98f8-4765-b33a-478fa0939c96"
// title: ""
// type: :text
// key: "title"
// parent_id: "b53ed8be-6d5a-4c7d-ae17-5bcbbbbcde2d"
export const TitleA963cc5d98f84765B33a478fa0939c96 = "Leveling";

// id: "07e508ff-40a8-4841-ab09-8cd62c018ad3"
// title: ""
// type: :text
// key: "title"
// parent_id: "89d6d7eb-71fe-4d21-9cb1-7baaf0d0f623"
export const Title07e508ff40a84841Ab098cd62c018ad3 = "Resources";

// id: "8e425af2-9005-48da-8c3a-2b64ebe124d5"
// title: ""
// type: :reference
// key: "label"
// parent_id: "4ee24f73-9265-4f27-885a-e6709d06ef60"
export function Label8e425af2900548da8c3a2b64ebe124d5(props: any) {
  return (
    <DefaultLogoA38382a473dc4ddb9b44E054acadfacb parentTag="Label8e425af2900548da8c3a2b64ebe124d5" {...props} />
  );
}

// id: "0e295d91-e1aa-4a3d-8799-653c96408f96"
// title: ""
// type: :text
// key: "title"
// parent_id: "4d178ac1-4ef5-401e-b6e8-1983dc597ed9"
export const Title0e295d91E1aa4a3d8799653c96408f96 = "Join Us";

// id: "5a95f508-5c89-482b-ba53-0faa2c52929e"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "7327d444-254f-4483-9c3c-84008d352a7e"
export const NavMenuSlug5a95f5085c89482bBa530faa2c52929e = "members-primary-nav";

const Components = {
  MarketingCardsCfe5a64a3ffd4d45B8980b975dfe2f58,
  Title2b09facdA3a94f659bb22dffd17448d3,
  AdminPages5671275bA5094b1a95c4015579737fd8,
  DefaultHeaderF2d0a3fbCec645009a363b5d2d6603fb,
  MembersAreaHeaderFe61fbe35f234f6dAa46A8b649d2bbbd,
  MarketingBodyB777e83bDc034bfbA25cEdaf5e51c872,
  AboutUsPage2b3df8c92941452a88d7C4452d95b16a,
  PublicEventsPageF136ffc76b8b4e6bAe26A1e2e2b1bc75,
  MemberPagesFbe06bf2533b4a2e9c58398b64268edf,
  MembersAreaDefaultB6dc238dE935402aBe94Fd6ffc61b42f,
  SecondaryPage422d133bC3bd4797883088481771286b,
  ResourcesPage53dd25b0549f47b9B87eA47858d01f24,
  Label90bdcc2e1c654c72B94cAc4ad8557a5a,
  ShortHeader893c048d031649458577C5f294cf26f6,
  DefaultLogoA38382a473dc4ddb9b44E054acadfacb,
  PageQuote9d2d3e21E280476e9b92A63082a86bc9,
  JoinUsPageB36a41cdFe344146Aa654f78254999fe,
  HrefC1c314377c5543228941C93b83e7f9ec,
  Footer5868817a79dd4972Aae6A481fe417d06,
  SignupPages97a10b00B86e44bd9ac0E49bbaccf21d,
  SecondaryMarketingHero06222c36B1b5493cAf214a6039516320,
  HeaderBde89279C7bd44b8A212373733713505,
  DefaultFooter8ce2bfceB426499d8921372b6b074c1a,
  DefaultHeaderTopA9b59c5d2bf44530A98685411bd8c3a5,
  AboutLevelingPage4eca4826B85a4752Bff8413a028e3644,
  MessagePagesFdf11a998d7f4b54B98d57e0edc6a8a5,
  HomePageHeroA827bd3493da413488d6D188fe5447fe,
  SecondaryMarketingHeader90329289D1e74df2Ac428e2dc71f852a,
  HomePageD0158d3514c64110B470F271dfcf2aad,
  HomeHeaderA0836cebC83244959b8aBbdaebbc9654,
  Links885aa121967f432bA79136962dc137b8,
  HeroC7d8be7f42494012B66e02830715fc93,
  FooterFafc8816A0ba4303Be34D425b2efc728,
  Header5dfb60514ecf4cf48fecE26011afb458,
  Header7286e8267b984875Bd50Bc2070fe4596,
  Header5d0fd9a6Bc534dfe9e272d53bcadc6b4,
  WelcomeBackEa6f50b310a640d69f1eA467972320fa,
  Footer5d756c0d6b804ec98a7b20ccf4c2eda3,
  Footer59766fd427344a0d93b1403377190dce,
  Footer9cd3011aDda341b7Bf27A2ca70c68cbd,
  Header734baf256a394226943fF635f5d036df,
  PostSlugEc2f7cfbCde54a8b80329b573ad5b364,
  Header8df78367509f4d5d80b3Fc31f6f6dd6a,
  LinkedLogo4ee24f7392654f27885aE6709d06ef60,
  BodyE3aa356a528040618ef7Fbe546c576a7,
  HeaderB53ed8be6d5a4c7dAe175bcbbbbcde2d,
  HeroD880fe19Acdd41b1A22d08caa6af4bea,
  ButtonClass195ac1db195242ce8e2566429166f30b,
  SecondaryNav5044db2fBf9546aaBaa16c7e78d15f3f,
  Copyright9cfe1f851e42453a812920493759de7b,
  LinkedLogoFd9e5da010ea456dA067B9a267fa6e90,
  Header4d178ac14ef5401eB6e81983dc597ed9,
  Title50fb55ca1f5b4d57A8849b603985d769,
  BackgroundImage3dd86d7fAdf548c196df9e1cce0681a4,
  MarketingCards0867cb7c29bb431fB19d0292ee4b5344,
  ButtonA7a2e25d5ac248b3B149Aa5658753617,
  Header3af59ec65f54481eB9692645ff3e6d7f,
  FooterAf01124a2a12493e98fcA85973ddd253,
  Footer5f0d4f2471b944dbA3f6365fd0f2a058,
  NavigationC7511b7166034ee189921edf1cfea4dd,
  Header3415a38731c349daA09a790f2cb1917b,
  HeaderTop9296f890357c494bB248A9e12b509136,
  Footer8e74b66c32864d4597b4E0d9acfa35b0,
  Title25eab6aaFeb14735Abba977458fcbbd7,
  Header89d6d7eb71fe4d219cb17baaf0d0f623,
  BackgroundImage0f1211325ba947b5A0b1766d693ed5ce,
  Navigation7327d444254f44839c3c84008d352a7e,
  Logo25ebbc1f776b492994a9698451297f1c,
  HeaderTop0872b38fEf4b400eA2bc36541bbdd635,
  HeaderEde6ddf98a0b4d178175429a652315fb,
  Alt89b3e307743c4389B3aaFb4f274ee059,
  GroupflowC2153c5277a84bf684cf6eaa2b1269b0,
  NavMenuSlugA626566a21174e8fB1cd5b69b7383dbc,
  Body771c2b5b9f0a4497A7c8C9c9647fc51e,
  Quote3a39c5d5Fe9243e8Afb1Ad262918ed6d,
  ImageUrl4bbba4fd52a04432Affe59e747356668,
  Title7c8783cbAc054cfeAddd9376c5abc003,
  HeaderTop17333cbaCc1f40c1B41e167509dc9552,
  WidgetId9a90141756e34758Abd6032458e903b9,
  WidgetId6baf44eeAb02435993a340e4d1ec519c,
  NavMenuSlugEc78659015f041a98ff4357c56e1cfff,
  Href3859f80dC2c247f1B50d2f9371bf4954,
  Title8bbae1417ddc4b4a8cec73e8ae95a2c8,
  LabelC1cc8fcd065b409c941d91fc78afb3ba,
  Url5f9d7f3eF6c5471aBb17A205c6d3ed06,
  ClassC41c8b7d3d9346ac961106b2e0eb82c7,
  ContentSlug5c2725ed2ca24b95Bec5E1b63b3f2da7,
  TitleD8791d6f7f1e4de6Adf62851fc38611b,
  NavMenuSlugF42144db96dc4b28852dFf618f1931a9,
  Class7880d464E87d4823Bec68785e6e5b316,
  Title3d5c2b54320b42f7B7b85dc6adf8eb95,
  Title0880ef234fd947d6Ae9b79c932795bde,
  NavMenuSlug3cebc62aBb214e7eB8fdEe9c63e1540f,
  NavMenuSlugBd07ffd9Eb854d3bAbc501990681f7f5,
  PostSlug7c1e46bc74284498Ae454dd869516e7e,
  LabelD0e34a4d8707406999c965bad58d833a,
  PostSlug4e906c65B82b4c1cA444E9b58b2d1249,
  Title84dc10da9678497f8581F24cba594233,
  TitleA963cc5d98f84765B33a478fa0939c96,
  Title07e508ff40a84841Ab098cd62c018ad3,
  Label8e425af2900548da8c3a2b64ebe124d5,
  Title0e295d91E1aa4a3d8799653c96408f96,
  NavMenuSlug5a95f5085c89482bBa530faa2c52929e
}

export default Components;