const ROUTES = {
  "/": {
    "name": "HomePageD0158d3514c64110B470F271dfcf2aad",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPages5671275bA5094b1a95c4015579737fd8",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage2b3df8c92941452a88d7C4452d95b16a",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPage4eca4826B85a4752Bff8413a028e3644",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/events/public": {
    "name": "PublicEventsPageF136ffc76b8b4e6bAe26A1e2e2b1bc75",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPageB36a41cdFe344146Aa654f78254999fe",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPagesFbe06bf2533b4a2e9c58398b64268edf",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePagesFdf11a998d7f4b54B98d57e0edc6a8a5",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPage53dd25b0549f47b9B87eA47858d01f24",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPages97a10b00B86e44bd9ac0E49bbaccf21d",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;